<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import {
	SwitchRoot,
	type SwitchRootEmits,
	type SwitchRootProps,
	SwitchThumb,
	useForwardPropsEmits,
} from 'radix-vue';
import { cn } from '@laam/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';

const toggleContainerVariants = cva(
	/* tw */ 'peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-400 focus-visible:ring-offset-0 focus-visible:ring-opacity-15 focus-visible:ring-offset-white disabled:cursor-not-allowed data-[state=checked]:bg-gray-900 data-[state=unchecked]:bg-gray-200 hover:data-[state=unchecked]:bg-gray-300 disabled:data-[state=checked]:bg-gray-400 disabled:data-[state=unchecked]:bg-gray-300',
	{
		variants: {
			size: {
				default: /* tw */ 'h-5xl w-[36px]',
				lg: /* tw */ 'h-7xl w-[44px]',
			},
		},
		defaultVariants: {
			size: 'default',
		},
	},
);

const toggleThumbVariants = cva(
	/* tw */ 'pointer-events-none block rounded-full bg-white shadow-sm ring-0 transition-transform data-[state=unchecked]:translate-x-none',
	{
		variants: {
			size: {
				default: /* tw */ 'h-3xl w-3xl data-[state=checked]:translate-x-3xl',
				lg: /* tw */ 'h-5xl w-5xl data-[state=checked]:translate-x-5xl',
			},
		},
		defaultVariants: {
			size: 'default',
		},
	},
);

export type ToggleContainerVariants = VariantProps<
	typeof toggleContainerVariants
>;

const props = defineProps<
	SwitchRootProps & {
		class?: HTMLAttributes['class'];
		size?: ToggleContainerVariants['size'];
	}
>();

const emits = defineEmits<SwitchRootEmits>();

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;
	return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
	<SwitchRoot
		v-bind="forwarded"
		:class="cn(toggleContainerVariants({ size: props.size }), props.class)"
	>
		<SwitchThumb :class="cn(toggleThumbVariants({ size: props.size }))" />
	</SwitchRoot>
</template>
